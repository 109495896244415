import { BaseInputProps } from '@utils/types/baseComponents';
import TextInput from '@components/_shared/textInput';
import clsx from 'clsx';
import React from 'react';
import styles from './styles.module.scss';

type Props = {
  /** Forgot password callback */
  onForgotPasswordClick: () => void;
  labelStyles?: string;
} & BaseInputProps;

/** Text input */
export function PasswordInput(props: Props) {
  const {
    label,
    onPressEnter,
    onTextChange,
    onForgotPasswordClick,
    labelStyles,
    placeholder,
    error,
    touched,
    required,
    name,
  } = props;

  const onChange = (value: string) => {
    onTextChange && onTextChange(value);
  };

  return (
    <div className={styles.container}>
      <div className={clsx([styles.inputHeader, labelStyles])}>
        <span>{label}</span>
        <span onClick={onForgotPasswordClick} className={styles.forgotPassword}>
          Forgot your password?
        </span>
      </div>

      <TextInput
        name={name}
        required={required}
        containerStyle={styles.inputContainer}
        type={'password'}
        placeholder={placeholder}
        onTextChange={onChange}
        error={error}
        touched={touched}
        {...(!!onPressEnter && { onPressEnter })}
        newAuthField
      />
    </div>
  );
}
