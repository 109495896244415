import React from 'react';
import { Form, Formik } from 'formik';
import clsx from 'clsx';

import { BaseComponentProps } from '@utils/types/baseComponents';
import Yup from '@utils/yup';
import Button from '@components/_shared/button';
import TextInput from '@components/_shared/textInput';
import { PasswordInput } from '@components/_shared/passwordInput';

import styles from './styles.module.scss';

export type LoginInFormTypes = typeof loginInitialValues;

type Props = {
  onSubmit: (values: LoginInFormTypes) => void;
  onPasswordForgot: () => void;
} & BaseComponentProps;

const loginInitialValues = {
  email: '',
  password: '',
};

const validationsSchema = Yup.object().shape({
  email: Yup.string(),
  password: Yup.string(),
});

export function LoginFormFields(props: Props) {
  const { onSubmit, onPasswordForgot, containerStyle } = props;

  return (
    <Formik
      initialValues={loginInitialValues}
      onSubmit={({ email, password }) => onSubmit({ email, password })}
      validationSchema={validationsSchema}
    >
      {({ errors, touched, setFieldValue }) => (
        <Form className={clsx(containerStyle)}>
          <TextInput
            name="email"
            required
            touched={touched.email}
            label="Email"
            type={'email'}
            onTextChange={(value) => setFieldValue('email', value)}
            error={errors.email}
            newAuthField
          />

          <PasswordInput
            required
            label="Password"
            name="email"
            onForgotPasswordClick={onPasswordForgot}
            onTextChange={(value) => setFieldValue('password', value)}
            error={errors.password}
            touched={touched.password}
          />

          <Button
            type="submit"
            containerStyle={styles.singInButton}
            variant="blueContained"
          >
            Log in
          </Button>
        </Form>
      )}
    </Formik>
  );
}
