import { BaseComponentProps } from '@utils/types/baseComponents';
import { Logo as LogoComponent } from '@assets/icons';
import clsx from 'clsx';
import React from 'react';
import { colors } from '@utils/constants/colors';
import styles from './styles.module.scss';
import Link from 'next/link';

type Props = {
  /** Logo variant base on rove colors */
  variant: keyof typeof colors;
} & BaseComponentProps;

Logo.defaultProps = {
  variant: 'black',
};

/** Rove logo */
export function Logo(props: Props) {
  const { variant, containerStyle } = props;

  return (
    <div className={clsx([styles.logoContainer, containerStyle])}>
      <Link href="/" passHref>
        <a>
          <LogoComponent fill={colors[variant]} />
        </a>
      </Link>
    </div>
  );
}
