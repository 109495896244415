import { facebookSignIn } from '@api/auth/facebookSignIn';
import { googleSignIn } from '@api/auth/googleSignIn';
import { LoginInFormTypes } from '@components/authorization/loginFormFields';
import { Login } from '@components/authorization/login';
import { useSession } from '@utils/context/session';
import { withSession } from '@utils/hocs';
import type { NextPage } from 'next';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import Head from 'next/head';

const LoginPage: NextPage = () => {
  const router = useRouter();
  const { login } = useSession();
  const [errorMessage, setErrorMessage] = useState<string>();

  const onSignUpClick = () => router.push('sign-up');
  const onPasswordForgot = () => router.replace('forgot-your-password');

  const onSubmit = async (values: LoginInFormTypes) => {
    try {
      await login(values.email, values.password);
      router.replace((router.query.from as string) || '/');
    } catch (error: any) {
      setErrorMessage('Your email address or password is incorrect.');
    }
  };

  return (
    <>
      <Head>
        <title>Log In</title>
      </Head>
      <div>
        <Login
          onFacebookLoginClick={() =>
            facebookSignIn((router.query.from as string) || '/')
          }
          onGoogleLoginClick={() =>
            googleSignIn((router.query.from as string) || '/')
          }
          onSignUpClick={onSignUpClick}
          onPasswordForgot={onPasswordForgot}
          onSubmit={onSubmit}
          errorMessage={errorMessage}
        />
      </div>
    </>
  );
};

export default withSession(LoginPage);
